import React from 'react';
import styled from 'styled-components';

import media from '../../utils/mediaQueries';
import scale from '../../utils/scale';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import ConditionalHide from '../../atoms/ConditionalHide';

import CheckboxList from '../../components/CheckboxList';

const NavStep = styled.div.attrs(({ isParent, isCurrent }) => ({
  active: isParent || isCurrent,
}))`
  z-index: 5;
  display: ${props => (props.active ? 'flex' : 'none')};
  flex: 0 0 auto;
  flex-wrap: wrap;
  border-bottom: 1px solid ${props => props.theme.color.border};
  background: ${props => props.theme.color.gray};
`;

const NavStepInfo = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.border};
  border-width: 0;
  padding: ${scale(1)};
  padding-left: ${scale(1.5)};
  width: 100%;

  ${media.md`
    border-width: 0 2px 0 0;
    width: 33%;
  `}
`;

const NavStepItemsWrapper = styled.div`
  position: relative;
  display: ${props => (props.isParent ? 'none' : 'block')};
  width: 100%;

  ${media.md`
    display: block;
    width: 67%;
  `}
`;

const NavStepItems = styled.div`
  position: relative;
  border-top: 1px solid ${props => props.theme.color.border};
  padding: ${scale(1)};
  padding-right: ${scale(1.5)};
  height: 100%;
  background: ${props => props.theme.color.bg};
  transition: background 0.25s easeInOut;

  ${media.md`
    border: 0;

    ${props =>
      props.disabled &&
      `
      display: block;
      background: transparent;
      opacity: .3;
    `}
  `}
`;

const ButtonHolder = styled.div`
  margin-top: 0;
  text-align: right;
`;

const ButtonVisibleWrapper = styled(ConditionalHide)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-end;
`;

const LabelSelected = styled(Text)`
  width: 100%;
  height: 20px;

  && {
    padding-left: ${scale(2)};
  }
`;

const NavStepContent = props => (
  <>
    <Text bold as="p">
      {props.level}) {props.question}
    </Text>

    <LabelSelected italic use="p">
      {props.labelSelected}
    </LabelSelected>

    <ButtonVisibleWrapper hide={!props.showButton} isOnlyVisible>
      <ButtonHolder>
        <Button label="Change" useLink link={props.linkBack} />
      </ButtonHolder>
    </ButtonVisibleWrapper>
  </>
);

export default ({
  isParent,
  isCurrent,
  selected,
  level,
  question,
  linkBack,
  ...props
}) => (
  <NavStep isParent={isParent} isCurrent={isCurrent} {...props}>
    <NavStepInfo>
      <NavStepContent
        showButton={isParent}
        level={level}
        question={question}
        linkBack={linkBack}
        labelSelected={selected ? selected.label : ''}
      />
    </NavStepInfo>

    <NavStepItemsWrapper isParent={isParent}>
      <NavStepItems disabled={isParent}>
        <CheckboxList
          items={props.items}
          selected={selected ? selected.key : null}
          disabled={isParent}
          useLink
        />
      </NavStepItems>
    </NavStepItemsWrapper>
  </NavStep>
);
