import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

import Heading from '../atoms/Heading';
import CompanyInfoItem from './CompanyInfoItem';
import logo from '../assets/images/GmbH-Logo.svg';

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  border-bottom: 1px solid ${props => props.theme.color.border};
`;

const LogoBox = styled.div`
  padding: ${scale(0.5)} ${scale(1.5)};
  width: 100%;

  ${media.md`
    border-right: 1px solid ${props => props.theme.color.border};
    width: 50%;
  `}
`;

const LogoHolder = styled.h1`
  margin: 0;
  padding-top: ${scale(0.75)};

  img {
    width: 212px;
    height: auto;

    ${media.md`
      width: auto;
      height: 128px;
    `}
  }
`;

const CompanyInfo = styled.dl`
  display: none;

  ${media.md`
    display: block;
    width: 50%;
  `}
`;

export default () => (
  <StaticQuery
    query={graphql`
      query CompanyInfoQuery {
        defaults: allPrismicSiteSettings {
          edges {
            node {
              data {
                label_logo
                label_information_1
                information_1
                label_information_2
                information_2
                label_information_3
                information_3
                label_information_4
                information_4

                meta_title_default
              }
            }
          }
        }
      }
    `}
    render={data => {
      const defaults = data.defaults.edges[0].node.data;

      return (
        <Wrapper>
          <LogoBox>
            <Heading headingSize={3} as="div">
              {defaults.label_logo}
            </Heading>

            <LogoHolder>
              <img src={logo} alt={defaults.meta_title_default} />
            </LogoHolder>
          </LogoBox>

          <CompanyInfo>
            <CompanyInfoItem label={defaults.label_information_1}>
              {defaults.information_1}
            </CompanyInfoItem>

            <CompanyInfoItem label={defaults.label_information_2}>
              {defaults.information_2}
            </CompanyInfoItem>

            <CompanyInfoItem label={defaults.label_information_3}>
              {defaults.information_3}
            </CompanyInfoItem>

            <CompanyInfoItem label={defaults.label_information_4}>
              {defaults.information_4}
            </CompanyInfoItem>
          </CompanyInfo>
        </Wrapper>
      );
    }}
  />
);
