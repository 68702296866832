import React from 'react';
import styled from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';

import Heading from '../atoms/Heading';

const Label = styled(Heading)`
  && {
    padding: ${scale(0.5)} ${scale(1.5)} 0;

    ${media.md`
      padding-left: ${scale(1)};
    `}
  }
`;

const Content = styled(Heading)`
  border-bottom: 1px solid ${props => props.theme.color.border};

  :last-child {
    border: 0;
  }

  && {
    padding-left: ${scale(1.5)};
    padding-right: ${scale(1.5)};

    ${media.md`
      padding-left: ${scale(1)};
      padding-bottom: ${scale(0.25)};
    `}
  }
`;

export default ({ label, children }) => (
  <>
    <Label headingSize={3} use="dt">
      {label}
    </Label>
    <Content headingSize={1} use="dd">
      {children}
    </Content>
  </>
);
