import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { itemsToCheckboxList, hasSubNavigation } from '../../utils/navigation';

import Container from './Container';
import Step from './Step';

const MainNavigation = ({ data, path, selectedItems, ...props }) => {
  const navigationLevel1 =
    data.navigation.edges[0].node.data.main_navigation.document[0];
  const basePathLevel1 = '/';

  return (
    <Container {...props}>
      <Step
        level={1}
        question={navigationLevel1.data.question}
        items={itemsToCheckboxList(
          basePathLevel1,
          navigationLevel1.data.navigation_items
        )}
        linkBack={basePathLevel1}
        isCurrent={path === basePathLevel1}
        isParent={path.slice(0, -1).includes(basePathLevel1)}
        selected={selectedItems[0]}
      />

      {navigationLevel1.data.navigation_items
        .filter(itemLevel2 => hasSubNavigation(itemLevel2))
        .map(itemLevel2 => {
          const navigationLevel2 = itemLevel2.item_link.document[0];
          const basePathLevel2 = `/${itemLevel2.item_slug}/`;

          // Navigation Level 2
          return (
            <React.Fragment key={basePathLevel2}>
              <Step
                level={2}
                question={navigationLevel2.data.question}
                items={itemsToCheckboxList(
                  basePathLevel2,
                  navigationLevel2.data.navigation_items
                )}
                linkBack={basePathLevel2}
                isCurrent={path === basePathLevel2}
                isParent={path.slice(0, -1).includes(basePathLevel2)}
                selected={selectedItems[1]}
              />

              {navigationLevel2.data.navigation_items
                .filter(itemLevel3 => hasSubNavigation(itemLevel3))
                .map(itemLevel3 => {
                  const navigationLevel3 = itemLevel3.item_link.document[0];
                  const basePathLevel3 = `/${itemLevel2.item_slug}/${
                    itemLevel3.item_slug
                  }/`;

                  // Navigation Level 3
                  return (
                    <Step
                      key={basePathLevel3}
                      level={3}
                      question={navigationLevel3.data.question}
                      items={itemsToCheckboxList(
                        basePathLevel3,
                        navigationLevel3.data.navigation_items
                      )}
                      linkBack={basePathLevel3}
                      isCurrent={path === basePathLevel3}
                      isParent={path.slice(0, -1).includes(basePathLevel3)}
                      selected={selectedItems[2]}
                    />
                  );
                })}
            </React.Fragment>
          );
        })}
    </Container>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        navigation: allPrismicSiteSettings {
          edges {
            node {
              ...NavigationTreeContent
            }
          }
        }
      }
    `}
    render={data => <MainNavigation data={data} {...props} />}
  />
);
