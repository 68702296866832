import styled from 'styled-components';

export default styled.div`
  ${props =>
    props.isOnlyVisible
      ? `
    visibility: ${props.hide ? 'hidden' : 'visible'};
  `
      : `
    display: ${props.hide ? 'none' : 'block'};
  `};
`;
