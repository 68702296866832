import styled from 'styled-components';

import media from '../../utils/mediaQueries';

export default styled.nav`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: ${props => props.theme.color.grayLight};
  border-top: 1px solid ${props => props.theme.color.border};

  ${media.md`
    border: 0;
  `}
`;
