// no es6 exports since functions are also used by gatsby-node

const itemsToCheckboxList = (basePath, items) =>
  items
    .filter(item => item.item_status === 'active')
    .map(item => ({
      key: item.item_slug,
      label: item.item_label.html,
      link: `${basePath}${item.item_slug}/`,
    }));

/* eslint-disable no-underscore-dangle */
const hasSubNavigation = item =>
  item.item_status === 'active' &&
  item.item_link !== null &&
  item.item_link.document[0].__typename === 'PrismicNavigation';
/* eslint-enable no-underscore-dangle */

module.exports.itemsToCheckboxList = itemsToCheckboxList;
module.exports.hasSubNavigation = hasSubNavigation;
